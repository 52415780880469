<template>
     <div class=" service body">
          <div class="container">
               <div class="columns">
                    <div class="column is-half">
                         <div class="vertical-align">
                              <div class="section-header-2">
                                   Distributed Antenna System
                              </div>
                              <p>
                                   Distributed Antenna System (DAS) is the solution that provides mobile coverage inside township, where the coverage,
                                   capacity or quality would not have been otherwise satisfactory. CTLs Distributed Antenna System (DAS) design meets
                                   cabling innovation with operator-grade, enterprise-ready solutions. Our DAS portfolio of in-building wireless
                                   infrastructure solutions offers built-in RF intelligence and flexible architectures to fit every facility, large or
                                   small. These innovative solutions enable a wide variety of technologies and advanced 4G LTE for the newest breed of
                                   mobile devices. The result is a solution that is widely recommended, adopted and installed in thousands of
                                   deployments worldwide.
                              </p>
                         </div>
                    </div>
                    <div class="column is-half"><img class="sideimg" src="@/assets/images/ites.png" alt="" /></div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Digital Active DAS
                              </div>
                              <div>
                                   <p>
                                        A DAS consists of a master Unit or head-end linked to a mobile operator’s base station. The main hub transmits
                                        the mobile signal to remote units (RUs) and antennas, which amplify the signal in specific areas of the venue.
                                        Because of traffic demands, large venues are often divided into sectors, in which a specific frequency band or
                                        operator’s service is distributed.
                                   </p>

                                   <p>
                                        DAS solutions transport RF signals in digital format from the Master Unit to the RU. This means that the
                                        signal does not attenuate nor suffer any impairments or distortion between the master unit and RU, and the
                                        master unit can simulcast the digital signal to all of the RUs in the system. Digital systems can also be
                                        configured to support other frequency reuse factors, up to and including single frequency reuse. In other
                                        words, DAS systems can be configured to operate at any point on the deployment continuum emphasising coverage
                                        to capacity. Digital transport offers peak RF performance and greater system architecture scale and
                                        flexibility compared to analogue solutions, and far less fibre infrastructure while offering maximum bandwidth
                                        to capacity crowds.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Functional Features
                              </div>
                              <p>
                                   Creates a common and non- discriminatory comprehensive coverage solution, which provides equal access for all
                                   wireless operators in CDMA, GSM900, GSM1800, WCDMA 2100 and LTE 2300.
                              </p>

                              <p>
                                   Accommodates all current technologies and capacity requirements with the ability to add capacity and support
                                   emerging future technologies as need arises. In short , it’s a scalable system design
                              </p>
                              <p>The system supports independent cell topologies for each Technology with an optimised link budget</p>
                              <p>
                                   Active devices used in this project can be controlled and monitored locally or remotely via EMS(Element Management
                                   system)
                              </p>
                              <p>Aesthetically pleasing single, multi-band antenna system avoids the visual disarray of antennas.</p>
                              <p>Easiness for optimisation & flexible design.</p>
                         </div>
                    </div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Intelligent Design
                              </div>
                              <div>
                                   <p>
                                        The Total DAS portfolio enables design flexibility for high-rise, stadium, campus environments or single
                                        facility designs.
                                   </p>

                                   <p>
                                        Leverages Common Headend Architecture : Built on a single DAS that supports all combinations of remotes, for
                                        scalable and cost-effective coverage and capacity.
                                   </p>

                                   <p>
                                        Delivers Efficient Remote Power: Pre-configured, modular remotes simplify commissioning and minimise upgrade
                                        costs with standard, medium and high power, indoor and outdoor remote options.
                                   </p>
                                   <p>
                                        Improves Performance with Advanced Cabling Solutions: Take advantage of intelligent cabling solutions and
                                        reduce installation time with Plug & PlayTM, composite and stubbed hardware options.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Hybrid DAS
                              </div>
                              <p>
                                   CTL recommends Hybrid DAS which is a combination of Active and passive DAS. This DAS system supports multifarious
                                   requirements for any industry. This is done by creating a central control room for all mobile operators and
                                   distributing the coverage and capacity from the central location to multiple locations.
                              </p>

                              <p>
                                   We use the passive system for providing the coverage and capacity to the nearest area of the Central control room.
                                   We also apply the active system for providing the coverage for the far end area of the buildings. By using this
                                   hybrid solution, we can save the cost and extend the coverage and capacity to another location in the premises.
                              </p>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped></style>
